import { BaseModalComponentProps } from '@capturi/use-modal'
import { useProcessingModal } from '@capturi/use-processing-modal'
import { t } from '@lingui/macro'

export default function CancelActionModal({
  isOpen,
  onClose,
  action,
  label,
  description,
}: BaseModalComponentProps & {
  action: () => Promise<void>
  label?: string
  description?: string
}): React.ReactElement {
  const { component } = useProcessingModal({
    isOpen: !!isOpen,
    label: label ?? t`Applying changes...`,
    description,
    actionPromise: action,
    onClose,
  })

  return component
}
